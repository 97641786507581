<template>
  <div>
    <LayoutModelSheetPrint
      v-bind="$store.state.stone_model[_modelName].crudSetting.sheetprint"
      :modelName="_modelName"
    ></LayoutModelSheetPrint>
  </div>
</template>

<script>
export default {
  computed: {
    _modelName() {
      return this.$route.meta.modelName;
    },
  },
  mounted() {},
};
</script>